import { Helmet } from "react-helmet";
import mainclasses from "../style.module.css";
// import homeClasses from "./About.module.css";

const Security = (props) => {
    return (
        <>
            <Helmet>
                <title>Security || Sapid Books</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta name="description" content="Powerful Accounting Platform For Our Business" />
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-8 mx-auto text-center">
                        <h2 className={mainclasses.heading}>Security Policy for SapidBooks</h2>
                        <img
                            src={process.env.PUBLIC_URL + "/images/border.png"}
                            alt="" id="b-img" style={{ width: "38%" }}
                        />
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <p>At SapidBooks, we take security seriously. This Security Policy outlines our commitment to protecting your data and ensuring the security of our platform. We continuously work to maintain the highest standards of security to safeguard your sensitive financial information.</p>
                </div>
                <div className="row">
                    <ol>
                        <h4>
                            <li style={{ fontSize: "20px", padding: "0px 6px" }}>Data Security</li>
                        </h4>
                        <p>
                            Cookies are small text files that are placed on your device (computer, smartphone, or tablet) when you visit a website. They are widely used to make websites work more efficiently, enhance user experience, and provide information to website owners.</p>
                        <h4>
                            <li style={{ fontSize: "20px", padding: "0px 6px" }}>How We Use Cookies</li>
                        </h4>
                        <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
                            <p>
                                <li><b>Encryption:</b> We use strong encryption protocols to protect your data in transit and at rest. This ensures that your data remains confidential and secure.</li>
                            </p>
                            <p>
                                <li><b>Access Control:</b> Access to your data is restricted to authorized personnel only. We employ strict access control measures to prevent unauthorized access.</li>
                            </p>
                            <p>
                                <li><b>Data Backups:</b> We regularly backup your data to prevent data loss. These backups are securely stored and can be restored in the event of an unexpected incident.</li>
                            </p>
                        </ol>
                        <h4 style={{ fontSize: "20px", padding: "0px 6px" }}>
                            <li>User Authentication</li>
                        </h4>
                        <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
                            <p><li><b>Secure Passwords:</b> We encourage strong, unique passwords for user accounts. Passwords are stored securely using industry-standard hashing </li></p>
                            <p><li><b>Multi-Factor Authentication (MFA): </b>We offer MFA options to add an extra layer of security to your account. We recommend enabling MFA for all users.</li></p>
                        </ol>

                        <h4 style={{ fontSize: "20px" }}>
                            <li>Infrastructure Security</li>
                        </h4>
                        <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
                            <p><li><b>Hosting:</b> Our platform is hosted on secure, reliable infrastructure providers that adhere to stringent security standards.</li></p>
                            <p><li><b>Monitoring:</b> We continuously monitor our systems for any unusual activity or security breaches. This allows us to respond quickly to any potential threats.</li></p>
                        </ol>
                        <h4 style={{ fontSize: "20px" }}>
                            <li>Compliance</li>
                        </h4>
                        <p>We comply with relevant data protection regulations, such as GDPR, HIPAA, or other applicable industry-specific standards, as they pertain to your data.</p>

                        <h4 style={{ fontSize: "20px" }}><li>Security Training</li></h4>
                        <p>Our staff undergoes regular security training to stay updated on best practices and to remain vigilant against potential security threats.</p>

                        <h4 style={{ fontSize: "20px" }}> <li>Incident Response</li></h4>
                        <p>In the event of a security incident or breach, we have established procedures to respond promptly, investigate the incident, and take appropriate actions to mitigate any potential harm.</p>
                        <h4 style={{ fontSize: "20px" }}> <li>Third-Party Security</li></h4>
                        <p>We ensure that our third-party service providers adhere to security standards that align with ours to protect your data when it is in their care.</p>
                        <h4 style={{ fontSize: "20px" }}> <li>Vulnerability Management</li></h4>
                        <p>We conduct regular security assessments and vulnerability scans to identify and address potential weaknesses in our platform.</p>
                        <h4 style={{ fontSize: "20px" }}> <li>User Responsibilities</li></h4>
                        <p>While we take security seriously, users also play a crucial role in maintaining security. We urge users to:</p>
                        <ol>
                            <li>Use strong, unique passwords.</li>
                            <li>Keep login credentials confidential.</li>
                            <li>Enable Multi-Factor Authentication (MFA) where available.</li>
                            <li>Report any suspicious activity or security concerns.</li>
                        </ol>
                        
                        <h4 style={{ fontSize: "20px" }}> <li>Updates to Security Policy</li></h4>
                        <p>We may update this Security Policy to reflect changes in our security practices or in response to regulatory changes. Any updates will be posted on our website.</p>

                        <p>By using SapidBooks, you acknowledge that you have read, understood, and agreed to be bound by this Security Policy.</p>
                    </ol>
                </div>
            </div >
        </>
    );
};

export default Security;