import { Helmet } from "react-helmet";
import mainclasses from "../style.module.css";
// import homeClasses from "./About.module.css";

const Terms = (props) => {
    return (
        <>
            <Helmet>
                <title>Sapid Books</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="description"
                    content="Powerful Accounting Platform For Our Business" />
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-8 mx-auto text-center">
                        <h2 className={mainclasses.heading}>Terms Of Service</h2>
                        <img
                            src={process.env.PUBLIC_URL + "/images/border.png"}
                            alt="" id="b-img" style={{ width: "38%" }}
                        />
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <p>Welcome to SapidBooks! These Terms of Service outline the rules and regulations for using our website and services. By accessing or using the SapidBooks website, you agree to be bound by these terms. If you disagree with any part of these terms, please do not use our website.</p>
                </div>
                <div className="row">
                    <ol>
                        <h4>
                            <li style={{ fontSize: "20px", padding: "0px 6px" }}>Acceptance of Terms</li>
                        </h4>
                        <p>By using the SapidBooks website, you agree to comply with and be bound by these Terms of Service, as well as our Privacy Policy. If you do not agree with any of these terms, please refrain from using our website.</p>
                        <h4>
                            <li style={{ fontSize: "20px", padding: "0px 6px" }}>Use of Services</li>
                        </h4>
                        <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
                            <p><li><b>Account Creation:</b> To access certain features of our website, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.</li></p>
                            <p><li><b>User Conduct:</b> You agree to use our website only for lawful purposes and in accordance with these Terms of Service. You shall not use the website in any way that could damage, disable, overburden, or impair our servers or networks, or interfere with any other party's use and enjoyment of the website.</li>
                            </p>
                        </ol>
                        <h4 style={{ fontSize: "20px", padding: "0px 6px" }}>
                            <li>Intellectual Property</li>
                        </h4>
                        <p>All content on the SapidBooks website, including text, graphics, logos, images, and software, is the property of SapidBooks or its content suppliers and protected by copyright and other intellectual property laws. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our website.</p>

                        <h4 style={{ fontSize: "20px" }}><li>Limitation of Liability</li></h4>
                        <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
                            <p><li><b>Disclaimer of Warranties:</b> The SapidBooks website and its content are provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the accuracy, completeness, or usefulness of this information.</li></p>
                            <p><li><b>Limitation of Liability: </b>In no event shall SapidBooks, its officers, directors, employees, or agents be liable to you or any third party for any indirect, consequential, incidental, special, punitive, or exemplary damages.</li></p>
                        </ol>

                        <h4 style={{ fontSize: "20px" }}> <li>Links to Third-Party Websites</li></h4>
                        <p>Our website may contain links to third-party websites or services that are not owned or controlled by SapidBooks. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>
                    </ol>
                </div>
                <div>
                    <h4 style={{ fontSize: "20px" }}>Changes to Terms of Service</h4>
                    <p>We reserve the right to modify or replace these Terms of Service at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our website after any such changes constitutes your acceptance of the new Terms of Service.</p>
                </div>

                <div>
                    <h4 style={{ fontSize: "20px" }}>Governing Law and Dispute Resolution</h4>
                    <p>These Terms of Service shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in [Jurisdiction].</p>
                </div>
            </div >
        </>
    );
};

export default Terms;