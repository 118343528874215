import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import mainclasses from "../style.module.css";
import homeClasses from "./Home.module.css";

const Home = (props) => {
  return (
    <>
      <Helmet>
        <title>Sapid Books</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Powerful Accounting Platform For Our Business"
        />
      </Helmet>
      <div className="container-fluid p-0">
        <img
          src={process.env.PUBLIC_URL + "/images/banner.png"}
          alt=""
          className={homeClasses.banner}
        />
      </div>
      <div className="container-fluid bg-light">
        <div className="row">
          <div className="col-lg-12 mx-auto mt-5 mb-5 px-5 text-center">
            <h3 className={mainclasses.head1}>
              Powerful Accounting Platform for our Business
            </h3>
            <p className={mainclasses.para}>
              Sapid Books is a comprehensive cloud accounting platform that
              takes care of our business finance. From core accounting, stock
              tracking, bank reconciliation and keeping our business GST
              compliant, Sapid Books does it all and more.
            </p>
            <button className={homeClasses.download} type="download">
              <Link  className={homeClasses.downloadlink} to="https://sapid.in/exe/SapidBooks.zip" target="_blank" download>
              Download Now
              </Link>
              {/* <Link className={homeClasses.downloadlink} to="/download">
                Download Now
              </Link> */}
            </button>
          </div>
        </div>
      </div>
      <div className="container p-4">
        <h2 className={mainclasses.heading}>
          Why Sapid Books is the preferred accounting software of businesses
        </h2>
        <div className="row">
          <div className="col-md-4 col-12 col-lg-4">
            <div className={homeClasses.carddesign}>
              <center>
                <div className={homeClasses.icon}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/ete.png"}
                    alt=""
                  />
                </div>
              </center>
              <div className={homeClasses.cardbox}>
                <h2>End-to-end accounting</h2>
                <p>
                  Right from negotiating deals to raising sales orders and
                  invoicing, Sapid Books handles mundane accounting tasks so you
                  can focus on your business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className={homeClasses.carddesign}>
              <center>
                <div className={homeClasses.icon}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/gst.png"}
                    alt="" id="gst"
                  />
                </div>
              </center>
              <div className={homeClasses.cardbox}>
                <h2>GST compliance</h2>
                <p>
                  Create GST invoices, know your tax liability, and file your
                  tax returns directly. Sapid Books keeps your business GST
                  compliant.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <div className={homeClasses.carddesign}>
              <center>
                <div className={homeClasses.icon}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/user.png"}
                    alt=""
                  />
                </div>
              </center>
              <div className={homeClasses.cardbox}>
                <h2>User Friendly Interface</h2>
                <p>
                  Right from negotiating deals to raising sales orders and
                  invoicing, Sapid Books handles mundane accounting tasks so you
                  can focus on your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
