import { Helmet } from "react-helmet";
import mainclasses from "../style.module.css";
// import homeClasses from "./About.module.css";

const CookiePolicy = (props) => {
    return (
        <>
            <Helmet>
                <title>Sapid Books</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="description"
                    content="Powerful Accounting Platform For Our Business"
                />
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-8 mx-auto text-center">
                        <h2 className={mainclasses.heading}>Cookie Policy</h2>
                        <img
                            src={process.env.PUBLIC_URL + "/images/border.png"}
                            alt="" id="b-img" style={{ width: "38%" }}
                        />
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <p>
                        This Cookie Policy explains how SapidBooks uses cookies and similar tracking technologies on our website. By using our website, you consent to the use of cookies as described in this policy.
                    </p>
                </div>
                <div className="row">
                    <ol>
                        <h4>
                            <li style={{ fontSize: "20px", padding: "0px 6px" }}>What are Cookies?</li>
                        </h4>
                        <p>
                            Cookies are small text files that are placed on your device (computer, smartphone, or tablet) when you visit a website. They are widely used to make websites work more efficiently, enhance user experience, and provide information to website owners.</p>
                        <h4>
                            <li style={{ fontSize: "20px", padding: "0px 6px" }}>How We Use Cookies</li>
                        </h4>
                        <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
                            <p>
                                <li><b>Essential Cookies:</b> These cookies are necessary for the proper functioning of our website. They enable basic features like page navigation, security, and access to certain areas of the site.</li>
                            </p>
                            <p>
                                <li><b>Performance Cookies: </b>We use these cookies to collect information about how visitors use our website, such as which pages they visit most often and any error messages they might encounter. This helps us improve the performance of our website.</li>
                            </p>
                            <p>
                                <li><b>Functionality Cookies:</b> These cookies allow our website to remember choices you make, such as your username or language preferences, to provide a more personalized experience.</li>
                            </p>
                            <p>
                                <li><b>Advertising and Targeting Cookies:</b> We may use these cookies to deliver targeted advertisements based on your interests and browsing history. They also help us measure the effectiveness of our advertising campaigns.</li>
                            </p>
                        </ol>
                        <h4 style={{ fontSize: "20px", padding: "0px 6px" }}>
                            <li>Third-Party Cookies</li>
                        </h4>
                        <p>We may allow third-party services to use cookies on our website. These services include analytics providers, advertising networks, and social media platforms. These third parties may use cookies to collect information about your online activities over time and across different websites.</p>
                        <h4 style={{ fontSize: "20px" }}>
                            <li>Managing Cookies</li>
                        </h4>
                        <p>You can control and manage cookies in your browser settings. Most browsers allow you to block or delete cookies, as well as manage your preferences for each website. However, please note that disabling cookies may affect the functionality of our website.
                        </p>
                        <h4 style={{ fontSize: "20px" }}>
                            <li>Consent</li>
                        </h4>
                        <p>By using our website, you consent to the use of cookies as described in this Cookie Policy. You have the option to manage your cookie preferences through your browser settings.</p>
                        <h4 style={{ fontSize: "20px" }}><li>Changes to the Cookie Policy</li></h4>
                        <p>We may update this Cookie Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be effective immediately upon posting on our website.</p>
                    </ol>
                </div>
            </div >
        </>
    );
};

export default CookiePolicy;