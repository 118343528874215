import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
// import API from "../../Api";
// import Swal from "sweetalert2";
import classes from "./Download.module.css";
import mainclasses from "../style.module.css";

const Download = () => {
  // const Toast = Swal.mixin({
  //     toast: true,
  //     showConfirmButton: false,
  //     timer: 3000,
  //     timerProgressBar: false,

  // });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, event) => {
    event.preventDefault();
    reset();
    // API.post(`contact`, data)
    //     .then(res => {
    //         Toast.fire({
    //             icon: 'success',
    //             title: res.data.message
    //         });
    //     }).catch(err => {
    //         console.log(err)
    //     });
  };
  return (
    <>
      <Helmet>
        <title>Download | Sapid Books</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Fill the form to download our windows application." />
      </Helmet>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-8 mx-auto text-center">
            <h2 className={mainclasses.heading}>Explore Our Platform</h2>
            <img
              src={process.env.PUBLIC_URL + "/images/border.png"}
              alt="" id="b-img" style={{ width: "38%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="row">
              <div className="col-lg-7 ml-lg-auto shadow p-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/download.png"}
                  alt=""
                  className={classes.banner}
                />

              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-4 mt-4 shadow p-4">
                <form action=" " onSubmit={handleSubmit(onSubmit)}>
                  <div className={`mb-3  ${classes.inptcontdiv}`}>
                    <i className="fa fa-user-o" />
                    <input
                      type="text"
                      className={`form-control  ${classes.inptcont}`}
                      placeholder="Full Name *"
                      maxLength="50"
                      {...register("username", {
                        required: true,
                        pattern: /^[A-Za-z ]+$/i,
                      })}
                      name="username"
                      id="username"
                      autoComplete="off"
                    />
                    {errors.username && (
                      <p className={classes.error_p}>
                        Please enter a valid name.
                      </p>
                    )}
                  </div>
                  <div className={`mb-3  ${classes.inptcontdiv}`}>
                    <i className="fa fa-phone" />
                    <input
                      type="text"
                      className={`form-control  ${classes.inptcont}`}
                      placeholder="Contact No *"
                      name="mobile"
                      id="mobile"
                      maxLength="10"
                      {...register("mobile", {
                        required: true,
                        maxLength: 10,
                        minLength: 10,
                        pattern: /^[0-9\b]+$/,
                      })}
                      autoComplete="off"
                    />
                    {errors.mobile && (
                      <p className={classes.error_p}>
                        Enter a valid Mobile number.
                      </p>
                    )}
                  </div>
                  <div className={`mb-3  ${classes.inptcontdiv}`}>
                    <i className="fa fa-envelope-o" />
                    <input
                      type="email"
                      className={`form-control  ${classes.inptcont}`}
                      placeholder="Email"
                      name="email"
                      id="email"
                      {...register("email", { required: true })}
                      autoComplete="off"
                    />
                  </div>
                  {errors.email && (
                    <p className={classes.error_p}>Email is required.</p>
                  )}
                  <div className={`mb-3  ${classes.inptcontdiv}`}>
                    <i className="fa fa-commenting-o" />
                    <textarea
                      className={`form-control  ${classes.inptconttextarea}`}
                      placeholder="Message *"
                      {...register("message", { required: true })}
                      rows="4"
                      minLength="10"
                      maxLength="250"
                      id="message"
                      name="message"
                    />
                    {errors.message && (
                      <p className={classes.error_p}>Message is required.</p>
                    )}
                  </div>
                  <button type="submit" className={`btn ${classes.btncont}`}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Download;
