import { Helmet } from "react-helmet";
import mainclasses from "../style.module.css";
// import homeClasses from "./About.module.css";

const PrivacyPolicy = (props) => {
  return (
    <>
      <Helmet>
        <title>Sapid Books</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Powerful Accounting Platform For Our Business"
        />
      </Helmet>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-8 mx-auto text-center">
            <h2 className={mainclasses.heading}>Privacy Policy</h2>
            <img
              src={process.env.PUBLIC_URL + "/images/border.png"}
              alt="" id="b-img" style={{ width: "38%" }}
            />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <p>
            Protecting your privacy is important to us. This Privacy Policy
            outlines how SapidBooks collects, uses, and protects your personal
            information when you use our website. By accessing or using the
            SapidBooks website, you consent to the practices described in this
            Privacy Policy.
          </p>
        </div>
        <div className="row">
          <ol>
            <h4>
              <li style={{ fontSize: "20px", padding: "0px 6px" }}>Information We Collect</li>
            </h4>
            <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
              <p>
                <li><strong>Personal Information:</strong> We may collect personal
                  information such as your name, email address, contact information,
                  and other details when you create an account, contact us, or use
                  our services.</li></p>
              <p>
                <li><strong>Usage Information:</strong> We collect information about
                  how you interact with our website, including the pages you visit,
                  your IP address, browser type, and operating system.</li>
              </p>
            </ol>
            <h4>
              <li style={{ fontSize: "20px", padding: "0px 6px" }}>How We Use Your Information ?</li>
            </h4>
            <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
              <p>
                <li>We use the collected information to provide, maintain, and improve
                  our services, respond to your inquiries, and personalize your
                  experience on our website.</li>
              </p>
              <p>
                <li>We may use your email address to send you updates, newsletters,
                  and promotional materials, but only if you've explicitly consented
                  to receive such communication.</li>
              </p>
              <p>
                <li>We may use cookies and similar technologies to enhance your
                  browsing experience, gather usage data, and analyze site traffic.</li>
              </p>
              <p>
                <li><strong>Advertising and Targeting Cookies:</strong> We may use
                  these cookies to deliver targeted advertisements based on your
                  interests and browsing history. They also help us measure the
                  effectiveness of our advertising campaigns.</li>
              </p>
            </ol>
            <h4 style={{ fontSize: "20px", padding: "0px 6px" }}>
              <li>Data Sharing</li>
            </h4>
            <ol className="info-list" style={{ listStyle: "lower-alpha", fontSize: "16px" }}>
              <li>
                We do not sell or rent your personal information to third parties
                for their marketing purposes.
              </li>
              <li>
                We may share your information with service providers who assist us
                in delivering our services, but they are bound by confidentiality
                agreements and are not authorized to use your information for
                their own purposes.
              </li>
            </ol>

            <h4 style={{ fontSize: "20px" }}>
              <li>Data Security</li>
            </h4>
            <p>
              We implement industry-standard security measures to protect your
              personal information from unauthorized access, alteration,
              disclosure, or destruction. However, no data transmission over the
              internet can be guaranteed to be 100% secure.
            </p>
            <h4 style={{ fontSize: "20px" }}>
              <li>Third-Party Links</li>
            </h4>
            <p>
              Our website may contain links to third-party websites. This Privacy
              Policy does not apply to those websites, so we recommend reviewing
              their respective privacy policies before providing any personal
              information.
            </p>
          </ol>
        </div>
        <div>
          <h4 style={{ fontSize: "20px" }}>Children's Privacy</h4>
          <p>
            Our website is not intended for individuals under the age of [age].
            We do not knowingly collect personal information from children. If
            you believe that we have inadvertently collected information from a
            child, please contact us to remove the information.
          </p>
        </div>

        <div>
          <h4 style={{ fontSize: "20px" }}>Your Choices</h4>
          <p>
            You have the right to review, update, or delete your personal
            information. You can also opt-out of receiving promotional emails by
            following the unsubscribe instructions in our emails.
          </p>
        </div>
        <div>
          <h4 style={{ fontSize: "20px" }}>Changes to this Privacy Policy</h4>
          <p>
            We reserve the right to update or change this Privacy Policy at any
            time. Changes will be effective immediately upon posting on our
            website. Your continued use of our website after any such changes
            signifies your acceptance of the updated Privacy Policy.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
