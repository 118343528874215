import { Helmet } from "react-helmet";
import mainclasses from "../style.module.css";
// import homeClasses from "./About.module.css";

const AntispamPolicy = (props) => {
    return (
        <>
            <Helmet>
                <title>Sapid Books</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="description"
                    content="Powerful Accounting Platform For Our Business"
                />
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-8 mx-auto text-center">
                        <h2 className={mainclasses.heading}>Anti Spam Policy</h2>
                        <img
                            src={process.env.PUBLIC_URL + "/images/border.png"}
                            alt="" id="b-img" style={{ width: "38%" }}
                        />
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <h4>Anti-Spam Policy for SapidBook Website</h4>
                    <p>Effective communication with our users is a priority at SapidBook, the accounting platform designed to streamline your financial management. To maintain a positive user experience and uphold the integrity of our platform, we have implemented a comprehensive Anti-Spam Policy. This policy outlines our commitment to responsible communication practices and outlines the rules governing the distribution of electronic messages.</p>
                    <h4>Definition of Spam</h4>
                    <p>Spam is defined as any unsolicited electronic communication, including emails, messages, or any form of digital correspondence, sent to individuals who have not explicitly opted to receive such communication from SapidBook. This includes, but is not limited to, promotional messages, advertisements, marketing campaigns, and any other content intended to promote our services.</p>
                    <h4>Our Commitment</h4>
                    <p>At SapidBook, we are committed to ensuring that our communication with our users is transparent, relevant, and respectful of their preferences. Our Anti-Spam Policy is designed to protect users from receiving unwanted communications while providing them with valuable information about our platform.</p>
                    <h4>User Consent</h4>
                    <p>We strictly adhere to obtaining user consent before sending any electronic communication. Users will be given the option to subscribe to our newsletters, updates, and promotional materials during their interactions with our platform. Consent may be obtained through explicit opt-in mechanisms such as checkboxes or confirmation emails.</p>
                    <h4>Communication Frequency</h4>
                    <p>We understand the importance of balance in communication. We will limit the frequency of our electronic communication to avoid overwhelming our users with messages. Users can choose their preferred frequency of communication during the subscription process and can easily adjust these settings within their account preferences.</p>
                    <h4>Easy Opt-Out</h4>
                    <p>We respect the choices of our users. Every communication sent by SapidBook will include a clear and straightforward opt-out mechanism. Users who no longer wish to receive our electronic communication can easily unsubscribe or adjust their communication preferences in their account settings.</p>
                    <h4>Content Relevance</h4>
                    <p>We strive to ensure that our communications are relevant and valuable to our users. Our messages will be tailored to provide information about new features, updates, educational content, and other topics related to financial management and the SapidBook platform.</p>
                    <h4>Data Privacy</h4>
                    <p>The privacy and security of user data are of utmost importance to us. We will never share, sell, or rent user contact information to third parties for the purpose of sending unsolicited communications.</p>
                    <h4>Compliance</h4>
                    <p>SapidBook's Anti-Spam Policy is aligned with international anti-spam laws and regulations, including but not limited to the CAN-SPAM Act. Our practices are regularly reviewed and updated to ensure ongoing compliance with evolving legal standards.</p>
                </div>
            </div >
        </>
    );
};

export default AntispamPolicy;