import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";

const Navigation = (props) => {
  const [menuToggle, setMenuToggle] = useState(false);
  let { pathname } = useLocation();
  function toggleMenuHandler() {
    setMenuToggle((curState) => !curState);
  }
  const hideShowClass = menuToggle
    ? "collapse navbar-collapse show"
    : "collapse navbar-collapse";

  //const loginHideShowClass = menuToggle ? "display: none":"display: none";
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary  nav ">
        <div className="container">
          <Link className="navbar-brand" to="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="logo"
                className={`logoimg imgResponsive`}
              />
            </Link>
          <button
            className="navbar-toggler navbar-toggler-right mr-auto"
            type="button"
            onClick={toggleMenuHandler}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={hideShowClass} onClick={toggleMenuHandler}>
            <ul className="navbar-nav">
              <li className="nav-item mr-5 px-2">
                <Link
                  className={`nav-link ${pathname === "/" ? "active" : ""}`}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mr-5 px-2">
                <Link
                  className={`nav-link ${
                    pathname === "/about" ? "active" : ""
                  }`}
                  to="/about"
                >
                  About
                </Link>
              </li>
              {/* <li className="nav-item mr-5 px-2">
                <Link
                  className={`nav-link ${
                    pathname === "/download" ? "active" : ""
                  }`}
                  to="/download"
                >
                  Download
                </Link>
              </li> */}
              <li className="nav-item mr-5 px-2">
                <Link
                  className={`nav-link ${
                    pathname === "/contact" ? "active" : ""
                  }`}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            
          </div>
          <button type="button" className="loginbtn">Login</button>
        </div>

      </nav>
    </div>
  );
};

export default Navigation;
